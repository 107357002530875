<template>
  <div>
    <!--begin::Notice-->
    <div
      class="alert alert-custom alert-white alert-shadow gutter-b"
      role="alert"
    >
      <div class="alert-icon alert-icon-top">
        <span class="svg-icon svg-icon-3x svg-icon-primary mt-4">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/Tools/Tools.svg" />
          <!--end::Svg Icon-->
        </span>
      </div>
      <div class="alert-text">
        <p>
          <span
            class="label label-inline label-pill label-info label-rounded mr-2"
          >
            Информация:
          </span>
          Выберите тест для прохождения.
        </p>
      </div>
    </div>
    <!--end::Notice-->

    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Тесты</h3>
        </div>
      </div>
      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab title="Порученные" :active="resolveProcesses.length > 0">
            <b-card-group columns>
              <b-card
                @click.prevent="operateChecklist(process)"
                v-for="process in resolveProcesses"
                :key="process.id"
                :title="process.processName"
                border-variant="info"
                >назначен {{ parseTime(process.dateBegin, "dd-mm-yyyy") }}
                <b-card-text
                  ><b-badge
                    :variant="
                      process.criticality_level === 'Низкая'
                        ? 'info'
                        : process.criticality_level === 'Средняя'
                        ? 'warning'
                        : 'danger'
                    "
                    >Критичность: {{ process.criticality_level }}</b-badge
                  >&nbsp;<b-badge
                    variant="primary"
                    v-if="checkEmpty(process.propcessStatus)"
                    >Новый</b-badge
                  ><b-badge variant="warning" v-else>{{
                    process.propcessStatus.status
                  }}</b-badge>
                  {{ process.description }}</b-card-text
                >
                <b-button href="#" variant="primary">Заполнить</b-button>
              </b-card>
            </b-card-group>
            <!--<div
              @click.prevent="operateChecklist(process)"
              class="card gutter-b border-info"
              v-for="process in resolveProcesses"
              :key="process.id"
            >
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label"><b-badge variant="primary" v-if="checkEmpty(process.propcessStatus)">Новый</b-badge><b-badge variant="warning" v-else>Начатый</b-badge> {{ process.processName }} </h3>
                </div>
              </div>
              <div class="card-body">
                <p>{{ process.description }}</p>
                <button class="btn btn-primary">Заполнить</button>
              </div>
            </div>-->
          </b-tab>
          <b-tab title="Доступные" :active="resolveProcesses.length < 1">
            <b-card-group columns>
              <b-card
                @click.prevent="operateChecklist(process)"
                v-for="process in processes"
                :key="process.id"
                :title="process.processName"
                :border-variant="process.resolveProcess ? 'danger' : 'success'"
              >
                <b-card-text
                  ><b-badge
                    variant="primary"
                    v-if="checkEmpty(process.propcessStatus)"
                    >Новый</b-badge
                  ><b-badge variant="warning" v-else>{{
                    process.propcessStatus.status
                  }}</b-badge>
                  {{ process.description }}</b-card-text
                >
                <b-button href="#" variant="primary">Заполнить</b-button>
              </b-card>
            </b-card-group>

            <!--<div
              @click.prevent="operateChecklist(process)"
              class="card gutter-b"
              :class="
                !process.resolveProcess ? 'border-danger' : 'border-success'
              "
              v-for="process in processes"
              :key="process.id"
            >
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">{{ process.processName }} <b-badge variant="primary" v-if="checkEmpty(process.propcessStatus)">Новый</b-badge><b-badge variant="warning" v-else>Начатый</b-badge> </h3>
                </div>
              </div>
              <div class="card-body">
                <p>{{ process.description }}</p>
                <button class="btn btn-primary">Заполнить</button>
              </div>
            </div>-->
          </b-tab>
        </b-tabs>
      </div>

      <div></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {
  FETCH_CHECKLIST,
  SET_COMMON_INFO
} from "@/core/services/store/checklist.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import KTUtil from "@/assets/js/components/util";

export default {
  name: "checklist-all",
  components: {},
  data() {
    return {
      processes: [],
      resolveProcesses: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },

  created() {
    this.loadProcesses();
    this.loadResolveProcesses();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Тесты" },
      { title: "Все доступные", path: "/checklist/all" }
    ]);
  },

  methods: {
    loadResolveProcesses() {
      return new Promise(resolve => {
        ApiService.get("api/v1/givememyprocessbuddy").then(({ data }) => {
          // console.log(data);
          this.resolveProcesses = data;
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    loadProcesses() {
      return new Promise(resolve => {
        ApiService.get("api/v1/givemeaprocessbuddy").then(({ data }) => {
          this.processes = data;
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    operateChecklist(process) {
      // show page loading
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      var payload = {
        id: process.id
      };
      var common_info = {
        processId: process.id,
        templateId: process.template_id
      };
      if (
        !KTUtil.isEmpty(process.propcessStatus) &&
        !KTUtil.isEmpty(process.mainProc)
      ) {
        //always not empty for assigned to resolve
        payload.statusId = process.propcessStatus.statusId;
        common_info.statusId = process.propcessStatus.statusId;
        this.fetchChecklist(payload, common_info);
      } else {
        ApiService.post("api/v1/spfm/", {
          process_id: process.id
        })
          .then(({ data }) => {
            common_info.statusId = data.status_id;
            this.fetchChecklist(payload, common_info);
          })
          .catch(response => {
            console.error(response);
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          });
      }
    },

    fetchChecklist(payload, common_info) {
      this.$store
        .dispatch(FETCH_CHECKLIST, payload, common_info)
        .then(() => {
          this.$store.commit(SET_COMMON_INFO, common_info);
          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          //console.log("going to operate");
          this.$router.push("/checklist/operate").catch(err => {
            throw new Error(`Problem handling something: ${err}.`);
          });
        })
        .catch(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },

    checkEmpty(obj) {
      return KTUtil.isEmpty(obj);
    },

    parseTime(datetime, format = "dd.mm") {
      var newdate = new Date(datetime);
      var dd = String(newdate.getDate()).padStart(2, "0");
      var mm = String(newdate.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = newdate.getFullYear();
      //var yy = yyyy.toString().substr(-2);
      //return dd + '.' + mm + '.' + yyyy;
      if (format === "dd.mm") {
        return dd + "." + mm;
      }
      if (format === "dd-mm-yyyy") {
        return dd + "-" + mm + "-" + yyyy;
      }
    }
  }
};
</script>
